.site-down-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-image: url("../../assets/images/design.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--color-hot-pink);

    .innerContent {
        max-width: 60vw;
        width: 100%;
        max-height: 90vh;
        background-color: #fff;
        overflow: hidden;
        box-shadow: 0 0 50px 0 rgba(0,0,0,0.2);
        border: 2px solid #000;
        padding: 30px;

        .image-wrapper {
            width: 100%;
            height: 150px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .big-title {
            text-align: center;
            font-size: 38px;
            line-height: 48px;
            color: #000;
            margin: 30px 0;
            font-family: var(--font-heading);
        }

        .desc {
            text-align: center;

            .email {
                text-decoration: underline;
                color: var(--color-hot-pink);
                cursor: pointer;
            }
        }

        .footer {
            max-width: 600px;
            background-color: rgb(255, 105, 180, 0.1);
            border-left: 3px solid var(--color-hot-pink);
            margin: 30px auto;
            padding: 20px;
            color: #000;
            font-style: italic;

            p {
                font-size: 24px;
            }

            span {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                img {
                    width: 40px;
                }
            }
        }
    }



    @media (max-width: 767px) {
        .innerContent {
            max-width: 90vw;
        }
    }
    @media (max-width: 576px) {
        .innerContent {
            .image-wrapper {
                height: 100px;
            }
            .big-title {
                font-size: 24px;
                line-height: 30px;
            }
            .footer {
                padding: 10px;

                p {
                    font-size: 20px;
                }
            }
        }
    }
}