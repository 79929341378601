/******************************** WIZARD */
.wizard {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;

    .gotoEventBtn {
        text-align: center;
        margin-top: 25px;

        .button {
            background: var(--color-hot-pink);
            padding: 8px 16px;
            border-radius: 0;
            color: #fff;
            font-weight: 600;
            text-transform: none;
            &:hover {
                filter: brightness(0.8);
            }
    }
}

    &.show {
        opacity: 1;
        pointer-events: all;
        animation: fadeIn 0.2s;
    }

    .wizard_inner {
        background-color: #fff;
        width: 70vw;
        max-height: 95vh;
        padding: 30px;
        padding-bottom: 0;
        overflow-y: scroll;
        overflow-x: hidden;

        @media (max-width: 767px) {
            width: 95vw;
            max-height: 90vh;
        }

        &::-webkit-scrollbar {
            width: 0;
        }

        .wizard_title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-family: var(--font-heading);
                color: #000;
                font-size: 20px;
                text-transform: uppercase;
            }

            .wizard_close {
                text-align: right;

                svg {
                    width: 40px;
                    height: 40px;
                    font-size: 30px;
                    transition: 0.2s;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: auto;
                    cursor: pointer;

                    &:hover {
                        path {
                            stroke: var(--color-hot-pink);
                        }
                    }
                }
            }
        }

        .wizard_content {
            padding: 30px 0;

            .form-group {
                label {
                    color: #000;
                }

                .image-upload {
                    cursor: pointer;
                    width: 100%;
                    height: 200px;
                    overflow: hidden;
                    position: relative;
                    border: 1px solid grey;
                    border-radius: 0.35rem;

                    .overlay {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 30px;
                        background-color: rgba(255, 255, 255, 0.5);
                        backdrop-filter: blur(3px);
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid #000;
                        pointer-events: none;
                        border-radius: 0.35rem;
                        opacity: 0;
                        transition: 0.1s;

                        i {
                            color: #000;
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    &:hover {
                        .overlay {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }

                &.input-error {
                    .form-control {
                        border: 2px solid red;

                        &::placeholder {
                            color: red;
                        }
                    }

                    label,
                    .error-text {
                        font-weight: bold;
                        color: red;
                    }
                }

                .input-group {
                    span {
                        background-color: #dfdfdf;
                        width: 40px;
                        text-align: center;
                        display: flex;
                        height: 38px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px 0 0 5px;
                    }
                }
            }

            .searchProducts {
                .wizard_products {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    grid-gap: 20px;

                    .product {
                        padding: 10px;
                        border: 2px solid transparent;
                        cursor: pointer;

                        .image-wrapper {
                            width: 100%;
                            height: 200px;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .product-title {
                            font-size: 16px;
                            color: #000;
                            font-weight: 700;
                            margin: 10px 0;
                            text-align: center;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            overflow: hidden;
                        }

                        .price {
                            color: var(--color-hot-pink);
                            font-weight: 700;
                            text-align: center;
                        }

                        &:hover {
                            background-color: #efefef;
                            border-color: #000;
                        }

                        &.selected {
                            background-color: rgb(0, 186, 81);

                            .product-title,
                            .price {
                                color: #fff;
                            }
                        }
                    }



                    @media (max-width: 767px) {
                        grid-template-columns: 1fr 1fr;

                        .product {
                            .image-wrapper {
                                height: 100px;
                            }
                        }
                    }
                }
            }

            .loginLink,
            .registerLink {
                color: blue;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .wizard_steps {
            .wizard_title {
                font-size: 30px;
            }

            &.step1 {
                .image-wrapper {
                    width: 150px;
                    height: 150px;
                    overflow: hidden;
                    margin: 0 auto 20px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .form-control {
                    text-align: center;
                    border: 0;
                    border-bottom: 2px solid #dfdfdf;
                    padding: 30px;
                    max-width: 70%;
                    margin: auto;
                    box-shadow: none;

                    &:hover,
                    &:focus {
                        border-color: var(--color-hot-pink);
                    }
                }

                .resend {
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &.step3 {
                .title {
                    color: #000;
                }

                .details {
                    padding: 10px 30px;
                    border: 1px solid #efefef;
                    max-width: 60%;
                    margin: 30px auto;
                    
                    @media (max-width: 991px) {
                        // text-align: center;
                        margin: 30px 0;
                        max-width: 100%;
                        width: 100%;
                    }

                    .item {
                        display: grid;
                        grid-template-columns: 1fr auto 2.5fr;
                        align-items: center;
                        grid-gap: 20px;
                        margin: 20px 0;

                        .left {
                            text-align: left;
                            color: #000;
                        }

                        .right {
                            text-align: left;
                            font-size: 20px;
                            font-weight: bold;
                            color: #000;
                        }

                        @media (max-width: 767px) {
                            grid-template-columns: 1fr;
                            grid-gap: 4px;

                            .left {
                                font-size: 12px;
                            }

                            .separator {
                                display: none;
                            }
                        }
                    }
                }
            }

            &.success {
                .wizard_content {
                    text-align: center;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 20px;
                    align-items: center;

                    @media (max-width: 991px) {
                        grid-template-columns: 1fr;
                    }

                    .image-wrapper {
                        width: 150px;
                        height: 150px;
                        overflow: hidden;
                        margin: 0 auto 20px;
    
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
    
                    .title {
                        color: #000;
                    }
    
                    .details {
                        .item {
                            color: #000;
                        }
                    }
    
                    .code {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #000;
                        margin: 20px 0;
    
                        .copyCode {
                            margin-left: 10px;
                            cursor: pointer;
    
                            svg {
                                width: 20px;
                                height: 20px;
                                margin-right: 5px;
                            }
    
                            &:hover {
                                filter: brightness(0.9);
                            }
                        }
                    }
                }
            }

            .wizard_buttons {
                text-align: right;
                position: sticky;
                bottom: 0;
                z-index: 9;
                background-color: #fff;
                padding-top: 20px;
                padding-bottom: 20px;
                border-top: 1px solid rgba(0,0,0,0.1);

                .button {
                    padding: 16px 32px;
                    border-radius: 0;
                    font-size: 16px;
                    font-family: var(--font-heading);
                    margin-left: 16px;
                    margin-bottom: 16px;

                    &.primary {
                        background-color: var(--color-hot-pink);
                        color: #fff;
                    }

                    &.secondary {
                        background-color: var(--color-secondary-light);
                        color: #fff;
                    }

                    &:hover {
                        filter: brightness(0.9);
                    }
                }

                @media (max-width: 767px) {
                    text-align: center;
                }
            }

        }

    }

    #recaptcha-container {
        display: flex;
        justify-content: center;
        margin: 15px 0;
    }

    .wizard_buttons.text-center.auth_button>div {
        display: none;
    }

    .quickWizardLoaderWrapper {
        width: 100vw;
        position: relative;
        height: 100vh;
    }

    .quickStartSuccessTeamMessage {
        width: 100%;

        .messagePreview {
            .innerContent {
                text-align: left;
                // border: 1px solid rgba(255, 255, 255, 0);
                background-color: rgba(0, 200, 0, 0.158);
                padding: 20px 25px 20px 25px;
                border-radius: 5px;
                font-size: 0.8rem;
                font-weight: 600;
                // max-width: 500px;
                color: green;
                position: relative;
                height: 150px;
                overflow: hidden;

                &::before {
                    content: '';
                    background-image: linear-gradient(transparent, rgba(255,255,255,0.8));
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }

                &.expanded {
                    height: auto;

                    &::before {
                        content: none;
                    }
                }
            }

            .readMore {
                text-align: right;
                margin: 10px 0 0;
                font-size: 10px;
                color: #606060;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 1px;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }


            .shareButtons {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                margin-top: 8px;
                flex-wrap: wrap;

                .button {
                    background: var(--color-hot-pink);
                    padding: 8px 16px;
                    border-radius: 0;
                    color: #fff;
                    font-weight: 600;
                    text-transform: none;
                    &:hover {
                        filter: brightness(0.8);
                    }
                }

                @media (max-width: 767px) {
                    display: grid;
                }
            }

        }

        .or {
            margin: 20px 0;
            position: relative;
            
            span {
                background-color: #fff;
                position: relative;
                z-index: 2;
                padding: 0 10px;
            }

            &::before {
                content: '';
                display: block;
                height: 2px;
                width: 100%;
                position: relative;
                top: 13px;
                left: 0;
                background: #dfdfdf;
            }
        }

        .shareLinkCopy {
            .title {
                margin: 0 0 5px;
                text-align: left;
                font-size: 12px;
            }
            .inputBox {
                display: grid;
                grid-template-columns: 1fr auto;
                align-items: center;
                height: 45px;

                .url {
                    background-color: #efefef;
                    border: 1px solid #dfdfdf;
                    padding: 10px;
                    white-space: nowrap;
                    text-align: left;
                    width: 100%;
                    overflow: hidden;
                }
                
                .btnCopyLink {
                    background-color: var(--color-hot-pink);
                    color: #fff;
                    font-weight: 600;
                    white-space: nowrap;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 10px 20px;
                    cursor: pointer;
                    &:hover {
                        filter: brightness(0.8);
                    }
                }
            }
        }

        

        .gotoevent {
            margin-top: 16px;

            
            .button {
                background: var(--color-hot-pink);
                padding: 8px 16px;
                border-radius: 0;
                color: #fff;
                font-weight: 600;
                text-transform: none;
                &:hover {
                    filter: brightness(0.8);
                }
            }


            &.downloadBtn {
                .button {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    margin-top: 0;
                    margin-bottom: 0;
                    font-size: 0.8rem;
                    padding: 8px 16px;
                    background-color: var(--color-secondary-lightest);
                    border: none;
                    color: #fff;
                    font-weight: bold;
                    cursor: pointer;
                    position: relative;
                    z-index: 0;
                    overflow: hidden;
                    height: 36px;
                    // box-shadow: 0 0 0 rgba(0,0,0,0);
                    // transition: 0.2s;
                    // animation: flashWave 2s ease-in-out infinite;
            
            
                    @keyframes flashWave {
                        0% {
                            box-shadow: 0 0 0 rgba(0,0,0,0);
                        }
                        50% {
                            box-shadow: 0 0 50px rgb(251, 104, 178, 0.8);
                        }
                        10% {
                            box-shadow: 0 0 0 rgba(0,0,0,0);
                        }
                    }
                    
                    &::after {
                        content: '';
                        height: 250%;
                        width: 80px;
                        background-color: rgba(255,255,255,0.5);
                        position: absolute;
                        left: -100px;
                        top: -75%;
                        transform: rotate(-25deg);
                        z-index: -1;
                        animation: sidebysideFlash 2s ease-in-out infinite;
                    }
                    
                    @keyframes sidebysideFlash {
                        75% {
                            left: -100px;
                        }
                        100% {
                            left: 150%;
                        }
                    }
                }
            }
        }

        .otherShares {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;

            .button {
                padding: 16px 24px;
                background-color: var(--color-hot-pink);
                color: #fff;
                border-radius: 0px;
                text-transform: none;
            }
        }
    }
}

/******************************** /WIZARD */

.eventJoinNotifierWrapper {

    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999;

    .eventJoinNotifier {
        background-color: white;
        width: 600px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        font-weight: 600;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.swal2-container {
    z-index: 999999999999999!important;
}