.orgSurveyFormWrapper {
    padding: 1.5rem 2rem;

    h4 {
        width: 100%;
        text-align: center;
        margin-bottom: 1.5rem;
    }

    .actionWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 1.5rem;

        button {
            border: none;
            background-color: green;
            color: white;
            padding: 3px 35px;
            font-size: 0.8rem;
            font-weight: 600;
        }
    }
    .error-text {
        color: red;
    }
}