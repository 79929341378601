.popup-store-page-wrapper {
    overflow-x: hidden;
    
    
    .container {
        max-width: 1360px;
    }
    
    .site-header {
        position: fixed;
        background-image: linear-gradient(rgba(0,0,0,0.5), transparent);

        .inner-wrapper {
            .left .logo {
                width: 100px;
                height: 100px;
            }

            .right .nav-menu .nav-item:not(.cta-btn) {
                color: #fff;
                
                &:hover {
                    color: #fff;
                }
            }
        }
        
        @media (max-width: 767px) {
            .inner-wrapper .right .nav-menu .nav-item:not(.cta-btn) {
                color: #fff;
            }
            .openMenu {
                i {
                    color: #fff;
                }
            }
        }
    }




    .cart-icon {
        position: fixed;
        right: 30px;
        top: 150px;
        z-index: 999999;
        border-radius: 100%;
        background-color: #fff;
        box-shadow: 0 0 50px rgba(0,0,0,0.2);
        width: 80px;
        height: 80px;
        padding: 20px;
        cursor: pointer;
        transition: 0.2s;
        
        svg {
            width: 100%;
            height: 100%;
            transition: 0.2s;

            path {
                fill: var(--color-hot-pink);
            }
        }

        .hintText {
            background-color: #000;
            color: #fff;
            display: inline-block;
            position: absolute;
            left: 50%;
            top: -35px;
            transform: translateX(-50%);
            text-align: center;
            white-space: nowrap;
            font-size: 14px;
            font-weight: 500;
            padding: 2px 4px;
            border-radius: 2px;
            opacity: 0;
            pointer-events: none;
            transition: 0.2s;
        }

        .count {
            position: absolute;
            background-color: var(--color-hot-pink);
            color: #fff;
            left: calc(100% - 20px);
            top: 0;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            font-size: 12px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            padding: 0 6px;

            span {
                margin-left: 2px;
                display: none;
            }
        }



        &:hover {
            background-color: var(--color-hot-pink);
            box-shadow: 0 0 100px rgba(0,0,0,0.5);
            width: 100px;
            height: 100px;

            svg path {
                fill: #fff;
            }

            .hintText {
                opacity: 1;
            }

            .count {
                width: auto;
                border-radius: 2px;
                top: -25px;
                left: 0;
                background-color: #000;
                color: #fff;
                font-size: 14px;

                span {
                    display: inline-block;
                }
            }
        }

    }


    .cart-modal-wrapper {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999999;
        // display: flex;
        align-items: center;
        justify-content: center;
        display: none;

        .cart-overlay {
            position: absolute;
            background-color: rgba(0,0,0,0.5);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
        }

        .cart-modal {
            background-color: #fff;
            width: 60vw;
            max-height: 95vh;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 30px;
            border-radius: 30px;

            &::-webkit-scrollbar {
                width: 0;
            }


            .inner-content {
                display: none;

                &.show {
                    display: block;
                    animation: fade 0.2s;
                }

                .modal-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .title {
                        font-size: 20px;
                        color: #000;
                        text-transform: uppercase;
                        font-family: var(--font-heading);
                    }

                    .close-modal {
                        width: 50px;
                        height: 50px;
                        padding: 10px;
                        border-radius: 10px;
                        transition: 0.2s;
                        margin-left: auto;
                        cursor: pointer;
                        background-color: #fff;
        
                        svg {
                            width: 100%;
                            height: 100%;
                        }
        
                        &:hover {
                            background-color: #dfdfdf;
                        }
                    }
                }

                .modal-buttons {
                    margin: 10px 0;

                    .button {
                        background-color: var(--color-hot-pink);
                        padding: 10px 20px;
                        border-radius: 0;
                        color: #ffffff;
                        transition: 0.2s;
                        margin-left: 10px;
                        font-family: var(--font-heading);

                        &.prev {
                            background-color: #dfdfdf;
                            color: #666;
                        }

                        &:hover {
                            filter: brightness(80%);
                        }
                    }
                }


                &.no-item-in-cart {
                    .image-wrapper {
                        width: 200px;
                        height: 200px;
                        margin: auto;
                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .text {
                        text-align: center;
                        .title {
                            font-size: 20px;
                            color: #000;
                            font-weight: 700;
                            margin: 10px 0;
                        }
                        .desc {
                            font-size: 16px;
                            margin: 20px 0;
                        }

                        .buttons {
                            .button {
                                background-color: var(--color-hot-pink);
                                color: #fff;
                                padding: 10px 20px;
                                border-radius: 0;

                                &:hover {
                                    filter: brightness(80%);
                                }
                            }
                        }
                    }
                }


                &.cart-listing {
                    .cart-details {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background-color: #efefef;
                        padding: 20px;
                        border-radius: 10px;
                        margin: 10px 0;

                        .total {
                            color: #000;
                            font-size: 16px;

                            span {
                                font-size: 20px;
                                font-weight: 700;
                            }
                        }

                        .removeAll {
                            font-family: var(--font-heading);
                            color: red;
                            cursor: pointer;
                            position: relative;
                            z-index: 1;
                            padding: 2px 0;
                            transition: 0.2s;
                            border-bottom: 1px solid red;

                            &:hover {
                                border-color: transparent;
                            }
                        }
                    }


                    .listing {
                        .list-item {
                            display: grid;
                            grid-template-columns: 100px 1fr 120px 100px auto;
                            grid-gap: 20px;
                            align-items: center;
                            margin: 20px 0;

                            .list-image-wrapper {
                                width: 100%;
                                height: 100px;
                                overflow: hidden;
                                border-radius: 10px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            .list-details {
                                .title {
                                    font-size: 20px;
                                    font-weight: 700;
                                    color: #000;
                                }

                                .amount {
                                    font-size: 16px;
                                }
                            }

                            .list-qty {
                                display: grid;
                                grid-template-columns: 1fr 1fr 1fr;
                                align-items: center;
                                justify-items: center;

                                .incre,
                                .decre {
                                    width: 30px;
                                    height: 30px;
                                    background-color: rgba(0,0,0,0.1);
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 100%;
                                    cursor: pointer;
                                    transition: 0.2s;

                                    svg {
                                        width: 20px;
                                        height: 20px;
                                    }

                                    &:hover {
                                        background-color: rgba(0,0,0,0.3);
                                    }
                                }

                                .value {
                                    color: #000;
                                }
                            }

                            .list-total-amount {
                                font-size: 20px;
                                text-align: center;
                                color: #000;
                                font-weight: 700;
                            }

                            .action {
                                .remove {
                                    font-size: 16px;
                                    font-family: var(--font-heading);
                                    color: red;
                                    cursor: pointer;
                                    position: relative;
                                    z-index: 1;
                                    padding: 2px 0;
                                    transition: 0.2s;
                                    border-bottom: 1px solid red;

                                    &:hover {
                                        border-color: transparent;
                                    }
                                }
                            }
                        }
                    }
                }


                &.person-details {
                    .form-wrapper {
                        .form-section {
                            margin: 20px 0;

                            .title {
                                font-family: var(--font-heading);
                                text-transform: uppercase;
                                border-bottom: 1px solid #dfdfdf;
                                padding-bottom: 10px;
                                margin-bottom: 10px;
                            }

                            .formError {
                                label {
                                    color: red;
                                    font-weight: 700;
                                }
                                .form-control {
                                    border: 2px solid red;
                                    background-color: rgba(255,0,0,0.1);
                                }
                                .errMsg {
                                    color: red;
                                    font-weight: 700;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }


                &.payment-details {
                    .total-things {
                        text-align: center;

                        .item {
                            margin: 10px 0;
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-gap: 20px;
                            align-items: flex-end;

                            .label {
                                text-align: right;
                                color: #000;
                            }
                            .value {
                                text-align: left;
                                font-size: 20px;
                                font-weight: 700;
                                color: #666;

                                span {
                                    display: block;
                                    width: 100px;
                                    // background-color: red;
                                    text-align: right;
                                }
                            }

                            &.total {
                                margin: 20px 0;
                                .label,
                                .value {
                                    font-size: 20px;
                                    color: #000;
                                    font-weight: 700;
                                }
                                .value {
                                    color: var(--color-hot-pink);
                                    font-size: 26px;
                                }
                            }
                        }


                        .bigActionButton {
                            text-align: center;
                            max-width: 400px;
                            margin: 30px auto;

                            .button {
                                display: block;
                                padding: 15px 25px;
                                border-radius: 0;
                                margin: 10px 0;

                                &.proceed {
                                    background-color: var(--color-hot-pink);
                                    color: #fff;
                                    font-size: 16px;

                                    &:hover {
                                        filter: brightness(80%);
                                    }
                                }

                                &.cancel {
                                    &:hover {
                                        background-color: #dfdfdf;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 767px) {
                width: 90vw;
                overflow-y: scroll;

                .cart-details {
                    display: block!important;
                    text-align: center;
                }

                .inner-content.cart-listing .listing .list-item {
                    grid-template-columns: 80px 1fr;

                    .list-image-wrapper {
                        height: 80px;
                    }
                }
            }
        }


        &.show {
            display: flex;
            animation: fadeIn 0.2s;
        }
    }
    
    
    
    .popup-store-page {
        // margin: 100px 0;

        .cover-image {
            width: 100%;
            height: 40vh;
            overflow: hidden;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    
        .store-top {
            // box-shadow: 0 0 100px rgba(0,0,0,0.1);
            padding: 50px;
            border-radius: 30px;
            position: relative;
    
            .logo-image {
                width: 250px;
                height: 250px;
                overflow: hidden;
                border-radius: 100%;
                margin: -130px auto 20px;
                border: 3px solid var(--color-hot-pink);
                background-color: #fff;
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
    
    
            .subtitle {
                text-align: center;
                font-size: 20px;
                color: #858796;
                font-weight: bold;
            }
            .title {
                text-align: center;
                margin: 4px 0;
                font-size: 48px;
                font-weight: bold;
                color: #000;

                @media (max-width: 767px) {
                    font-size: 32px;
                }
            }
    
    
            .fundraise-section {
                margin-top: 32px;
    
                .inner {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    margin-bottom: 8px;

                    @media (max-width: 767px) {
                        display: grid;
                        text-align: center;
                        grid-gap: 10px;
                        margin-bottom: 10px;
                    }
    
                    .left,
                    .right {
                        font-size: 20px;
                        font-weight: bold;
                        color: #858796;
    
                        span {
                            font-size: 30px;
                        }
                    }
                }
    
                .fund-track {
                    position: relative;
                    width: 100%;
                    box-shadow: 0 0 10px rgba(0,0,0,0.2);
                    border-radius: 100px;
                    padding: 8px;
    
                    .fund-track-complete {
                        display: block;
                        width: var(--complete-percent);
                        height: 10px;
                        background-color: var(--color-hot-pink);
                        border-radius: 100px;
                    }
                }
            }
        }
    
    
        .store-bottom {
            position: relative;
            margin: 30px 0;
    
            .about {
                // box-shadow: 0 0 100px rgba(0,0,0,0.1);
                // border: 1px solid #efefef;
                border-radius: 30px;
                margin-bottom: 30px;
                padding: 20px;
    
                .title {
                    font-size: 20px;
                    color: #858796;
                    font-weight: 600;
                    margin-bottom: 15px;
                    border-bottom: 1px solid #dfdfdf;
                    padding-bottom: 10px;
                }
    
                .image-big {
                    width: 100%;
                    height: 200px;
                    overflow: hidden;
                    border-radius: 20px;
                    margin-bottom: 20px;
    
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
    
            .leaderboard,
            .supporters {
                position: sticky;
                top: 30px;
                box-shadow: 0 0 100px rgba(0,0,0,0.1);
                padding: 20px;
                border-radius: 30px;
                margin-bottom: 30px;
                
                .title {
                    font-size: 20px;
                    color: #858796;
                    font-weight: 600;
                    margin-bottom: 15px;
                    border-bottom: 1px solid #dfdfdf;
                    padding-bottom: 10px;
                }
                
                .item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 20px;
    
                    .left {
                        display: flex;
                        align-items: center;
    
                        .image-wrapper {
                            margin-right: 8px;
                            flex: 0 0 50px;
                            width: 50px;
                            height: 50px;
                            overflow: hidden;
                            border-radius: 100%;
                            
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        
                        .name {
                            color: #000;
                        }
                    }
                }
            }

            .leaderboard {
                position: unset;

                .item {
                    // align-items: flex-start;

                    .left {
                        .name {
                            .customNote {
                                background-color: #efefef;
                                margin-top: 4px;
                                margin-right: 16px;
                                font-size: 12px;
                                color: #606060;
                                border-radius: 2px;
                                padding: 4px 8px;
                                position: relative;
                                display: flex;
                                align-items: center;

                                i {
                                    margin-right: 4px;
                                }

                                span {
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 1;
                                    overflow: hidden;
                                }

                                &::after {
                                    content: attr(data-note);
                                    position: absolute;
                                    background: #000;
                                    color: #fff;
                                    padding: 4px 8px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    right: -300px;
                                    z-index: 99;
                                    width: 300px;
                                    opacity: 0;
                                    transition: 0.2s;
                                }
                                &::before {
                                    content: '';
                                    height: 10px;
                                    width: 10px;
                                    border-right: 10px solid #000;
                                    border-top: 10px solid transparent;
                                    border-bottom: 10px solid transparent;
                                    position: absolute;
                                    right: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    opacity: 0;
                                    pointer-events: none;
                                    transition: 0.2s
                                }

                                &:hover::after,
                                &:hover::before {
                                    opacity: 1;
                                }

                            }
                        }
                    }
                }
            }
    
    
    
            .right {
                .title {
                    font-size: 32px;
                    color: #858796;
                    margin-bottom: 20px;
                    font-weight: 600;
                    border-bottom: 1px solid #dfdfdf;
                    padding-bottom: 10px;
                }
    
                .products {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 30px;
        
                    .product {
                        background-color: #fff;
                        border: 1px solid #efefef;
                        border-radius: 30px;
                        transition: 0.2s;
                        padding: 20px;
                        padding-bottom: 80px;
                        position: relative;
        
                        .image-wrapper {
                            width: 100%;
                            height: 350px;
                            overflow: hidden;
                            border-radius: 30px;
        
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
    
                        .name {
                            margin: 16px 0;
                            color: #000;
                            font-weight: 600;
                            font-size: 20px;
                        }

                        .desc {
                            position: relative;
                            margin-bottom: 15px;

                            span {
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                            }

                            &.canHide {
                                &::before {
                                    content: "Read more";
                                    display: block;
                                    text-align: right;
                                    position: absolute;
                                    bottom: -20px;
                                    right: 0;
                                    cursor: pointer;
                                    font-size: 12px;
                                    color: var(--color-hot-pink);
                                    width: 100%;
                                    text-align: right;
                                }
    
                                &.expanded {
                                    span {
                                        display: block;
                                    }
                                    &::before {
                                        content: "Read less";
                                    }
                                }
                            }

                        }
    
                        .price {
                            color: #000;
                            margin: 20px 0;
                            font-weight: 600;
                        }
    
                        &:hover {
                            box-shadow: 0 0 50px rgba(0,0,0,0.1);
                        }


                        &.donateProduct {
                            box-shadow: 0 0 10px rgb(255, 255, 0, 0.2);
                            background-color: rgb(255, 255, 0, 0.3);
                            position: relative;
                            top: 0px;
                            transition: 0.2s;

                            .desc {
                                color: #000;

                                // &::before {
                                //     content: none;
                                // }
                            }
                            
                            &:hover {
                                border-color: yellow;
                                box-shadow: 0 0 100px rgb(255, 255, 0, 0.5);
                                background-color: rgb(255, 255, 0, 0.1);
                                top: -20px;
                            }
                        }
                    }

                    @media (max-width: 767px) {
                        grid-template-columns: 1fr;
                    }
                }
            }
        }
    }

    .actionWrapper {
        padding-top: 25px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        bottom: 20px;
        right: 20px;

        .addToCartBtn {
            border: none;
            background-color: var(--color-hot-pink);
            color: white;
            padding: 5px 25px;
        }
    }

}