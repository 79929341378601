.copyToClipboardBtn {
    border: none;
    border-radius: 7px;
    background-color: rgb(0, 132, 255);
    color: white;
    padding: 2px 12px;
    font-size: 0.7rem;
    &:hover {
        background-color: rgb(0, 99, 192);
    }
}