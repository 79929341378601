.downloadAppModal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0,0,0,0.5);
	z-index: 999999999;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;

	&.show {
		opacity: 1;
		pointer-events: all;
	}

	.modalInner {
		background-color: #fff;
		max-width: 80vw;
		width: 100%;
		height: auto;
		padding: 40px;
		position: relative;

		.closeModal {
			position: absolute;
			right: 0;
			top: 0;
			padding: 20px;
			color: #000;
			z-index: 9999;
		}

		.image-wrapper {
			width: 120px;
			height: 120px;
			overflow: hidden;
			margin: 0 auto;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.title {
			text-align: center;
			margin: 16px 0;
			font-family: var(--font-heading);
			font-size: 32px;
			color: #000;
		}
		
		.desc {
			font-size: 14px;
			margin: 16px 0;
			color: #000;
			text-align: center;
		}

		.steps {
			margin-top: 32px;

			.step {
				color: #000;
				margin: 16px 0 0;
				font-size: 14px;;

				span {
					display: inline-block;
					background-color: rgba(0,0,0,0.1);
					padding: 4px 8px;
					border-radius: 5px;
				}

				.threeDots {
					padding: 4px 8px;
					border-radius: 2px;
					background-color: rgba(0,0,0,0.1);
				}
			}
		}
	}
}