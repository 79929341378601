.welcomeModalWrapper {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
        background-color: #fff;
        max-width: 800px;
        padding: 20px;

        .closeModal {
            text-align: right;

            i {
                font-size: 32px;
                cursor: pointer;

                &:hover {
                    color: #000;
                }
            }
        }

        .image-wrapper {
            width: 150px;
            height: 150px;
            margin: 0 auto;
        }

        .bigTitle {
            font-size: 32px;
            font-family: var(--font-heading);
            color: #000;
            text-align: center;
        }

        .desc {
            text-align: center;
            margin: 20px 0;
        }

        .buttons {
            text-align: center;

            .button {
                padding: 16px 24px;
                margin: 4px;
                background: var(--color-hot-pink);
                border-radius: 0;
                color: #fff;

                &:hover {
                    filter: brightness(0.8);
                }
            }
        }
    }

    .backWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 0 0 0.5rem;
        .back {
            font-size: 0.8rem;
            color: #5f5f5f;
            cursor: pointer;
            &:hover {
                color: #000;
            }
        }
    }
}