.popup-stores-page-wrapper {
    .container {
        max-width: 1360px;
    }
    
    .site-header {
        position: relative;

        .inner-wrapper .right .nav-menu .nav-item:not(.cta-btn) {
            color: #000;
            
            &:hover {
                color: #fff;
            }
        }
        
        @media (max-width: 991px) {
            .inner-wrapper .right .nav-menu .nav-item:not(.cta-btn) {
                color: #fff;
            }
            .openMenu {
                i {
                    color: #000;
                }
            }
        }
    }


    .popup-stores-page {
        .bigHeadingSection {
            text-align: center;
            margin-bottom: 100px;

            .bigHeading {
                font-size: 80px;
                line-height: 100%;
                font-family: var(--font-heading);
                text-transform: uppercase;
                color: #000000;
            }

            .divider {
                width: 100px;
                height: 2px;
                background-color: var(--color-hot-pink);
                margin: 20px auto;
            }

            .bigDesc {
                font-size: 24px;
                color: #000;
                max-width: 1000px;
                margin: auto;
            }

            .buttons {
                margin: 32px 0;

                .button {
                    background-color: var(--color-hot-pink);
                    color: white;
                    padding: 8px 24px;
                    font-family: var(--font-heading);
                    font-size: 18px;
                    border-radius: 0;
                    text-transform: uppercase;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    border: 0;
                    transition: 0.2s;

                    &:hover {
                        filter: brightness(0.9);
                    }
                }
            }
        }


        .bigImageSection {
            position: relative;
            overflow: visible;

            .popupStoreScreenshot {
                max-width: 1000px;
                height: auto;
                margin: auto;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .candyPic1 {
                pointer-events: none;
                position: absolute;
                right: -100px;
                bottom: -80px;
                max-width: 600px;
                height: auto;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .candyPic2 {
                pointer-events: none;
                position: absolute;
                left: -100px;
                bottom: -80px;
                max-width: 500px;
                height: auto;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .candyPic3 {
                pointer-events: none;
                position: absolute;
                top: -350px;
                left: -80px;
                z-index: -1;
                max-width: 550px;
                height: auto;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }



        .searchWrapper {
            margin: 100px 0;
            text-align: center;

            .title {
                font-size: 50px;
                color: #000000;
                font-family: var(--font-heading);
                text-transform: uppercase;
            }

            .divider {
                width: 100px;
                height: 2px;
                background-color: var(--color-hot-pink);
                margin: 20px auto 40px;
            }

            .form-control {
                max-width: 800px;
                margin: auto;
            }
        }



        .popupStoreCards {
            margin: 100px 0;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 30px;


            .popupStoreCard {
                cursor: pointer;

                .inner {
                    padding: 20px;
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
                    border-radius: 16px;
                    transition: 0.2s;
                    
                    .cardTop {
                        display: grid;
                        grid-template-columns: 1fr;
                        gap: 20px;

                        .imageWrapper {
                            width: 80px;
                            height: 80px;
                            border-radius: 100%;
                            overflow: hidden;
                            border-radius: 100px;
                            border: 4px solid #FFF;
                            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.50);

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .cardInfo {
                            .cardTitle {
                                color: #000;
                                font-size: 18px;
                                font-weight: 600;
                            }

                            .eventName {
                                font-size: 16px;
                                color: #8e8e8e;
                            }

                            .goalBar {
                                margin-top: 8px;
                                
                                span {
                                    font-size: 16px;
                                    color: var(--color-hot-pink);
                                }
                                
                                .bar {
                                    margin-top: 8px;
                                    position: relative;
                                    width: 100%;
                                    height: 6px;
                                    border-radius: 100px;
                                    background: #efefef;
                                    
                                    div {
                                        border-radius: 100px;
                                        height: 100%;
                                        background-color: #00ff19;                                        ;
                                    }
                                }
                            }
                        }
                    }


                    .cardBottom {
                        margin-top: 16px;

                        .desc {
                            position: relative;
                            padding-bottom: 10px;
                            margin-bottom: 20px;
    
                            span {
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                                position: relative;
                            }
    
                            &.canHide {
                                &::after {
                                    content: "Read more";
                                    display: block;
                                    text-align: right;
                                    position: absolute;
                                    bottom: -20px;
                                    right: 0;
                                    cursor: pointer;
                                    font-size: 12px;
                                    color: var(--color-hot-pink);
                                    width: 100%;
                                    text-align: right;
                                }
        
                                &.expanded {
                                    span {
                                        display: block;
                                    }
                                    &::after {
                                        content: "Read less";
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    .inner {
                        box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
    }



    @media (max-width: 1100px) {
        .popup-stores-page {
            .bigHeadingSection {
                .bigHeading {
                    font-size: 60px;
                }
            }


            .bigImageSection {
                .candyPic1 {
                    max-width: 500px;
                }
                .candyPic2 {
                    max-width: 400px;
                }
                .candyPic3 {
                    display: none;
                }
            }
        }
    }



    @media (max-width: 991px) {
        .popup-stores-page {
            .bigHeadingSection {
                margin-bottom: 50px;

                .bigHeading {
                    font-size: 40px;
                }
                .bigDesc {
                    font-size: 18px;
                }
            }


            .bigImageSection {
                .candyPic1,
                .candyPic2 {
                    display: none;
                }
            }



            .searchWrapper {
                .title {
                    font-size: 32px;
                }
            }



            .popupStoreCards {
                grid-template-columns: 1fr 1fr;
            }
        }
    }


    @media (max-width: 767px) {
        .popup-stores-page {
            .popupStoreCards {
                grid-template-columns: 1fr;
            }
        }
    }
}