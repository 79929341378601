.cart-icon {
    position: fixed;
    right: 30px;
    top: 120px;
    z-index: 999999;
    border-radius: 100%;
    background-color: #fff;
    box-shadow: 0 0 50px rgba(0,0,0,0.2);
    width: 80px;
    height: 80px;
    padding: 20px;
    cursor: pointer;
    transition: 0.2s;
    
    svg {
        width: 100%;
        height: 100%;
        transition: 0.2s;

        path {
            fill: var(--color-hot-pink);
        }
    }

    .hintText {
        background-color: #000;
        color: #fff;
        display: inline-block;
        position: absolute;
        left: 50%;
        top: -35px;
        transform: translateX(-50%);
        text-align: center;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        padding: 2px 4px;
        border-radius: 2px;
        opacity: 0;
        pointer-events: none;
        transition: 0.2s;
    }

    .count {
        position: absolute;
        background-color: var(--color-hot-pink);
        color: #fff;
        left: calc(100% - 20px);
        top: 0;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        font-size: 12px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        padding: 0 6px;

        span {
            margin-left: 2px;
            display: none;
        }
    }



    &:hover {
        background-color: var(--color-hot-pink);
        box-shadow: 0 0 100px rgba(0,0,0,0.5);
        width: 100px;
        height: 100px;

        svg path {
            fill: #fff;
        }

        .hintText {
            opacity: 1;
        }

        .count {
            width: auto;
            border-radius: 2px;
            top: -25px;
            left: 0;
            background-color: #000;
            color: #fff;
            font-size: 14px;

            span {
                display: inline-block;
            }
        }
    }

}


.cart-modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999999;
    // display: flex;
    align-items: center;
    justify-content: center;
    display: none;

    .cart-overlay {
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    .cart-modal {
        background-color: #fff;
        width: 60vw;
        max-height: 95vh;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 30px;
        border-radius: 30px;

        &::-webkit-scrollbar {
            width: 0;
        }


        .inner-content {
            display: none;

            &.show {
                display: block;
                animation: fade 0.2s;
            }

            .modal-title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .title {
                    font-size: 20px;
                    color: #000;
                    text-transform: uppercase;
                    font-family: var(--font-heading);
                }

                .close-modal {
                    width: 50px;
                    height: 50px;
                    padding: 10px;
                    border-radius: 10px;
                    transition: 0.2s;
                    margin-left: auto;
                    cursor: pointer;
                    background-color: #fff;
    
                    svg {
                        width: 100%;
                        height: 100%;
                    }
    
                    &:hover {
                        background-color: #dfdfdf;
                    }
                }
            }

            .modal-buttons {
                margin: 10px 0;

                .button {
                    background-color: var(--color-hot-pink);
                    padding: 10px 20px;
                    border-radius: 0;
                    color: #ffffff;
                    transition: 0.2s;
                    margin-left: 10px;
                    font-family: var(--font-heading);

                    &.prev {
                        background-color: #dfdfdf;
                        color: #666;
                    }

                    &:hover {
                        filter: brightness(80%);
                    }
                }
            }


            &.no-item-in-cart {
                .image-wrapper {
                    width: 200px;
                    height: 200px;
                    margin: auto;
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                .text {
                    text-align: center;
                    .title {
                        font-size: 20px;
                        color: #000;
                        font-weight: 700;
                        margin: 10px 0;
                    }
                    .desc {
                        font-size: 16px;
                        margin: 20px 0;
                    }

                    .buttons {
                        .button {
                            background-color: var(--color-hot-pink);
                            color: #fff;
                            padding: 10px 20px;
                            border-radius: 0;

                            &:hover {
                                filter: brightness(80%);
                            }
                        }
                    }
                }
            }


            &.cart-listing {
                .cart-details {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #efefef;
                    padding: 20px;
                    border-radius: 10px;
                    margin: 10px 0;

                    .total {
                        color: #000;
                        font-size: 16px;

                        span {
                            font-size: 20px;
                            font-weight: 700;
                        }
                    }

                    .removeAll {
                        font-family: var(--font-heading);
                        color: red;
                        cursor: pointer;
                        position: relative;
                        z-index: 1;
                        padding: 2px 0;
                        transition: 0.2s;
                        border-bottom: 1px solid red;

                        &:hover {
                            border-color: transparent;
                        }
                    }
                }


                .listing {
                    .list-item {
                        display: grid;
                        grid-template-columns: 100px 1fr 120px 100px auto;
                        grid-gap: 20px;
                        align-items: center;
                        margin: 20px 0;

                        .list-image-wrapper {
                            width: 100%;
                            height: 100px;
                            overflow: hidden;
                            border-radius: 10px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .list-details {
                            .title {
                                font-size: 20px;
                                font-weight: 700;
                                color: #000;
                            }

                            .amount {
                                font-size: 16px;
                            }
                        }

                        .list-qty {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            align-items: center;
                            justify-items: center;

                            .incre,
                            .decre {
                                width: 30px;
                                height: 30px;
                                background-color: rgba(0,0,0,0.1);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 100%;
                                cursor: pointer;
                                transition: 0.2s;

                                svg {
                                    width: 20px;
                                    height: 20px;
                                }

                                &:hover {
                                    background-color: rgba(0,0,0,0.3);
                                }
                            }

                            .value {
                                color: #000;
                            }
                        }

                        .list-total-amount {
                            font-size: 20px;
                            text-align: center;
                            color: #000;
                            font-weight: 700;
                        }

                        .action {
                            .remove {
                                font-size: 16px;
                                font-family: var(--font-heading);
                                color: red;
                                cursor: pointer;
                                position: relative;
                                z-index: 1;
                                padding: 2px 0;
                                transition: 0.2s;
                                border-bottom: 1px solid red;

                                &:hover {
                                    border-color: transparent;
                                }
                            }
                        }

                        &.donate-item {
                            background-color: rgba(255,255,0,0.2);
                            padding: 10px;
                            border-radius: 10px;
                        }
                    }
                }
            }


            &.person-details {
                .form-wrapper {
                    .form-section {
                        margin: 20px 0;

                        .title {
                            font-family: var(--font-heading);
                            text-transform: uppercase;
                            border-bottom: 1px solid #dfdfdf;
                            padding-bottom: 10px;
                            margin-bottom: 10px;
                        }

                        .formError {
                            label {
                                color: red;
                                font-weight: 700;
                            }
                            .form-control {
                                border: 2px solid red;
                                background-color: rgba(255,0,0,0.1);
                            }
                            .errMsg {
                                color: red;
                                font-weight: 700;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }


            &.payment-details {
                .total-things {
                    text-align: center;

                    .item {
                        margin: 10px 0;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 20px;
                        align-items: flex-end;

                        .label {
                            text-align: right;
                            color: #000;
                        }
                        .value {
                            text-align: left;
                            font-size: 20px;
                            font-weight: 700;
                            color: #666;

                            span {
                                display: block;
                                width: 150px;
                                // background-color: red;
                                text-align: right;
                            }
                        }

                        &.total {
                            margin: 20px 0;
                            .label,
                            .value {
                                font-size: 20px;
                                color: #000;
                                font-weight: 700;
                            }
                            .value {
                                color: var(--color-hot-pink);
                                font-size: 26px;
                            }
                        }
                    }


                    .bigActionButton {
                        text-align: center;
                        max-width: 400px;
                        margin: 30px auto;

                        .button {
                            display: block;
                            padding: 15px 25px;
                            border-radius: 0;
                            margin: 10px 0;

                            &.proceed {
                                background-color: var(--color-hot-pink);
                                color: #fff;
                                font-size: 16px;

                                &:hover {
                                    filter: brightness(80%);
                                }
                            }

                            &.continue {
                                background-color: rgba(128, 128, 128, 0.356);
                                color: #fff;
                                font-size: 14px;

                                &:hover {
                                    filter: brightness(80%);
                                }
                            }

                            &.cancel {
                                &:hover {
                                    background-color: #dfdfdf;
                                }
                            }
                        }
                    }


                    @media (max-width: 767px) {
                        .item {
                            display: block;

                            .value {
                                span {
                                    margin: auto;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 767px) {
            width: 90vw;
            overflow-y: scroll;

            .cart-details {
                display: block!important;
                text-align: center;
            }

            .inner-content.cart-listing .listing .list-item {
                grid-template-columns: 80px 1fr;

                .list-image-wrapper {
                    height: 80px;
                }
            }
        }
    }


    &.show {
        display: flex;
        animation: fadeIn 0.2s;
    }
}

.warning-modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999999;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;


    &.show {
        opacity: 1;
        pointer-events: all;
    }

    .warning-content {
        background-color: #ffffff;
        max-width: 550px;
        padding: 32px;
        max-height: 99vh;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 0;
        }

        .image-wrapper {
            text-align: center;
        }

        .bigHeading {
            margin: 32px 0;
            text-align: center;
            color: #000;
            text-align: center;
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        
        .desc {
            margin: 32px 0;
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .buttons {
            text-align: center;

            .cancel-button,
            .proceed-button {
                margin: 8px 0;

                span {
                    display: inline-block;
                    padding: 12px 16px;
                    color: #ffffff;
                    font-family: var(--font-heading);
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px; /* 150% */
                    text-transform: uppercase;
                    cursor: pointer;
                    transition: 0.2s;

                    &:hover {
                        filter: brightness(0.9);
                    }
                }
            }
            .cancel-button {
                span {
                    background-color: #546569;
                }
            }
            .proceed-button {
                span {
                    background-color: #FF69B4;
                }
            }
        }
    }



    @media (max-width: 767px) {
        .warning-content {
            max-width: 90vw;
            padding: 16px 8px;

            .image-wrapper {
                width: 100px;
                height: 100px;
                margin: auto;

                img {
                    width: 1005;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .bigHeading {
                margin: 16px 0;
                font-size: 20px;
            }
            
            .desc {
                margin: 16px 0;
                font-size: 14px;
            }

            .buttons {
                .cancel-button,
                .proceed-button {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}