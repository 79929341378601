.agree-to-terms {
    label {
        font-weight: 600;
        color: #000;

        span {
            color: var(--color-hot-pink);
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }


    .form-check {
        // display: inline-block;
    }

}

.termsPopup {
    position: fixed;
    max-width: 70vw;
    max-height: 90vh;
    width: 100%;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 30px 50px;
    box-shadow: 0 0 100px rgba(0,0,0,0.1);
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;

    &.show {
        opacity: 1;
        pointer-events: all;
        top: 50%;
        z-index: 99;
    }

    .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .h3 {
            text-align: left;
            font-weight: 700;
            color: #606060;
            margin: 0;
        }

        .close {
            cursor: pointer;

            &:hover {
                svg path {
                    stroke: var(--color-hot-pink);
                }
            }
        }
    }
    
    .innerContent {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 65vh;
        width: 100%;
        text-align: left;
    }

    .buttons {
        margin-top: 20px;

        .button {
            padding: 16px 32px;
            border-radius: 0;
            font-size: 16px;
            font-family: var(--font-heading);
            margin-left: 16px;
            margin-bottom: 16px;

            &.secondary {
                background-color: var(--color-secondary-light);
                color: #fff;
            }
            &.primary {
                background-color: var(--color-hot-pink);
                color: #fff;
            }
        }
    }
}