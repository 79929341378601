.imageCropperOuter {
    position: fixed;
    top: 0;
    z-index: 999999;
    left: 0;
    background-color: rgba(0, 0, 0, 0.425);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .imageCropper {
        background-color: white;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 150px;
        position: relative;
        padding-left: 60px;
        img {
            // width: 60vh;
            // maxheight: 60vh;
            // padding-bottom: ;
        }
        .ReactCrop {
            max-height: 60vh;
            margin-top: -12rem;
        }
        .ReactCrop__child-wrapper {

        }
        .actions {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            color: white;
            padding: 15px;
            background-color: rgb(0, 81, 255);
        }
    }
}