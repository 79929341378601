.image-upload {
    cursor: pointer;
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
    border: 1px solid grey;
    border-radius: 0.35rem;

    .overlay {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px;
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(3px);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #000;
        pointer-events: none;
        border-radius: 0.35rem;
        opacity: 0;
        transition: 0.1s;

        i {
            color: #000;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &:hover {
        .overlay {
            opacity: 1;
            pointer-events: all;
        }
    }

    .miniMediaActionWrapper {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: var(--color-hot-pink);
        border-radius: 100%;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        opacity: 1;
        z-index: 999;

        &:hover {
            opacity: 1;
        }

        i {
            color: #fff;
        }

        .actionMenu {
            position: absolute;
            top: 0px;
            right: 0px;
            border-radius: 4px;
            width: 150px;
            height: 60px;
            opacity: 1;
            
            .action {
                padding: 5px 15px;
                color: #fff;
                font-weight: 600;
                font-size: 0.8rem;
                background-color: var(--color-hot-pink);
    
                &:hover {
                    filter: brightness(0.9);
                }
            }
        }
    }

    video {
        object-fit: cover;
    }
}
.Toastify__toast-container.Toastify__toast-container--top-right {
    z-index: 999999;
}