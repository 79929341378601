.joinEventPopupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.719);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;

    .popWrapper {
        background-color: white;
        border-radius: 5px;
        width: 500px;
        min-height: 300px;

        .codeEditor {
            padding: 2rem 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .inputWrapper {
                padding-top: 5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                input {
                    border: none;
                    border-bottom: 2px solid grey;
                    outline: none;
                    text-align: center;
                    font-size: 2.5rem;
                    width: 300px;
                }

                .errorText {
                    padding-top: 12px;
                }
            }

            .actions {
                padding-top: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;

                .loader {
                    border: 4px solid #131212;
                    /* Light grey */
                    border-top: 4px solid #dd5100;
                    /* Blue */
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    animation: spin 0.9s linear infinite;
                }

                .cancel {
                    border: none;
                    background-color: rgba(202, 202, 202, 0);
                    color: rgba(19, 19, 19, 0.445);
                    padding: 4px 15px;
                    margin: 0 15px;
                    cursor: pointer;
                    font-size: 0.9rem;
                    font-weight: 600;

                    &:hover {
                        color: rgba(209, 0, 0, 0.884);
                    }
                }

                .submit {
                    border: none;
                    background-color: rgb(0, 230, 161);
                    color: rgb(71, 71, 71);
                    padding: 4px 35px;
                    font-weight: 600;
                    margin: 0 15px;
                    box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
                    &:disabled {
                        opacity: 0.2;
                        pointer-events: none;
                    }
                }
            }
        }

        .warningWrapper {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            p {
                margin: 0;
                padding: 5px 45px;
                border-radius: 4px;
                font-size: 0.8rem;
                margin-top: 16px;
                font-weight: 600;
                color: red;
                background-color: rgb(255, 205, 205);
            }
        }

        .eventDetailsViewer {
            padding: 2rem 2.5rem;
            display: flex;
            justify-content: center;
            align-items: start;
            flex-direction: column;
            h1 {
                padding-bottom: 20px;
            }
            p {
                font-size: 1.2rem;
                span {
                    cursor: pointer;
                    font-size: 0.9rem;
                    &:hover {
                    color: rgb(45, 100, 253);

                    }
                    // padding-bottom: 2px;
                }
            }

            .actions {
                padding-top: 2rem;
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;

                .loader {
                    border: 4px solid #131212;
                    /* Light grey */
                    border-top: 4px solid #dd5100;
                    /* Blue */
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    animation: spin 0.9s linear infinite;
                }

                .cancel {
                    border: none;
                    background-color: rgba(202, 202, 202, 0);
                    color: rgba(19, 19, 19, 0.445);
                    padding: 4px 15px;
                    margin: 0 15px;
                    cursor: pointer;
                    font-size: 0.9rem;
                    font-weight: 600;

                    &:hover {
                        color: rgba(209, 0, 0, 0.884);
                    }
                }

                .submit {
                    border: none;
                    background-color: rgb(0, 230, 161);
                    color: rgb(71, 71, 71);
                    padding: 4px 35px;
                    font-weight: 600;
                    margin: 0 15px;
                    box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
                    &:disabled {
                        opacity: 0.2;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}