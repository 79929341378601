.eventPayoutButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;


    .eventPayoutBtn {
        background-color: #FF69B4;
        padding: 5px 45px;
        font-size: 0.9rem;
        font-weight: 600;
        cursor: pointer;
        color: white;
        border-radius: 3px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        position: relative;
    
    
        .options {
            z-index: 999999999999;
            border-radius: 5px;
            position: absolute;
            top: 30px;
            left: 0;
            background-color: #c5367e;
            width: 100%;
            opacity: 0;
            pointer-events: none;
    
            .option {
                padding: 5px 20px;
                font-size: 0.8rem;
                color: white;
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                &:hover {
                    background-color: #e43b8f;
                }


                .payoutInfo {
                    position: relative;
                    cursor: pointer;
                    z-index: 9999999999999999999999;

                    display: none;
    
    
                    i {
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        font-size: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba(0,0,0,0.1);
                        color: #fff;
                    }
    
                    .info {
                        position: absolute;
                        font-size: 10px;
                        background-color: #000;
                        color: #fff;
                        border-radius: 2px;
                        opacity: 0;
                        pointer-events: none;
                        top: -24px;
                        left: 0;
                        transition: 0.2s;
                        white-space: nowrap;
                        padding: 2px 4px;
                    }
    
                    &:hover {
                        .info {
                            opacity: 1;
                        }
                    }
                }
    
                
                &.disabled {
                    background-color: #afafaf;
                }
                &.selected {
                    background-color: #ff1f8f;
                }


                &:not(.selected) {
                    .payoutInfo {
                        display: block;
                    }
                }
            }
        }
    
        &:hover {
            .options {
                opacity: 1;
                pointer-events: all;
            }
        }
    }


    
    @media (max-width: 767px) {
        margin: 20px 0;

        .eventPayoutBtn {
            .options {
                .option {
                    .payoutInfo {
                        .info {
                            white-space: pre-wrap;
                            width: 60vw;
                            top: -40px;
                            left: unset;
                            right: 0;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}



.bankwizard {
    position: sticky;
    background-color: unset;
    
    .wizard_inner {
        margin-top: 10px;
        padding: 10px;
        background: #efefef;
        width: 100%;
        height: auto;
        max-height: unset;

        label {
            font-size: 16px;
        }
    }

    .warningWrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        p {
            margin-bottom: 30px;
            padding: 5px 85px;
            border-radius: 4px;
            font-size: 1rem;
            margin-top: 16px;
            font-weight: 600;
            color: rgb(255, 102, 0);
            background-color: rgb(255, 232, 205);
        }
    }

    .errorWrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        p {
            margin-bottom: 30px;
            padding: 5px 85px;
            border-radius: 4px;
            font-size: 1rem;
            margin-top: 16px;
            font-weight: 600;
            color: rgb(255, 0, 0);
            background-color: rgb(255, 205, 205);
        }
    }
}