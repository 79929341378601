.modal-dialog {
    position: fixed;
    right: 0;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal.right .modal-body {
    padding: 15px 15px 80px;
}

.modal.right.fade .modal-dialog {
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
    right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
    border-radius: 0;
    border: none;
}

.modal-header {
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
}

/* ----- v CAN BE DELETED v ----- */

.org-card {
    background-color: rgb(255, 255, 255);
    padding: 25px 15px 25px 15px;
    margin-bottom: 10px;
    border: 1px solid rgb(208, 208, 208);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.154);
    border-radius: 5px;
    height: 180px;
    position: relative;
    transition: 0.2s;
    position: relative;

    .orgIcon {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 2rem;
    }

    &.create {
        display: flex;
        cursor: pointer;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 0;
        color: var(--color-hot-pink);

        .icon {
            font-size: 1.9rem;
            margin-bottom: 0px;
        }
    }

    .orgCode {
        margin-top: rem;
        font-size: 0.7rem;
        font-weight: 600;
    }

    .orgType {
        margin-top: -0.3rem;
        // margin-bottom: -0.3rem;
        font-size: 0.7rem;
        font-weight: 600;
    }




    .overlay {
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        top: 0;
        right: 0;
        bottom: 36px;
        left: 0;
        z-index: 9999;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        opacity: 0;
        transition: 0.2s;
        border-radius: 2px;
    
        .button {
            background-color: #fff;
            // color: #000;
            padding: 8px 16px;

            i {
                margin-right: 4px;
            }

            &:hover {
                background: #dfdfdf;
            }
        }
    }

    .big-image {
        width: 100%;
        padding: 16px 0;
        position: relative;

        .tagsWrapper {
            position: absolute;
            top: 4px;
            right: 4px;

            .statusTag {
                background: black;
                color: #000000;
                font-size: 0.8rem;
                padding: 2px 8px;
                border-radius: 100px;

                &.Active {
                    background-color: #00e732;
                }
                &.Expired {
                    background-color: #999999;
                }
                &.Scheduled {
                    background-color: #1ea5ff;
                }
            }
        }
    }

    .details {
        padding-top: 0!important;

        .card-title {
            color: #000;
            font-weight: 600;
        }

        .eventTypeTag {
            background-color: #ff69b4;
            font-size: 12px;
            display: inline-block;
            margin: 8px 0;
            color: #fff;
            padding: 2px 8px;
            border-radius: 100px;
        }

        p {
            font-size: 12px;
            color: #000;
        }
    }

    .org-card-buttons {
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
        position: relative;
        z-index: 9999;

        .leftButton {
            .eventManageBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                margin-top: 0;
                margin-bottom: 0;
                font-size: 0.8rem;
                border-radius: 0 0 0 5px;
                padding: 0 8px;
                background-color: var(--color-hot-pink);
                border: none;
                color: #fff;
                font-weight: bold;
                cursor: pointer;
                position: relative;
                z-index: 0;
                overflow: hidden;
                width: 100%;
                height: 36px;
                // box-shadow: 0 0 0 rgba(0,0,0,0);
                // transition: 0.2s;
                // animation: flashWave 2s ease-in-out infinite;
        
        
                @keyframes flashWave {
                    0% {
                        box-shadow: 0 0 0 rgba(0,0,0,0);
                    }
                    50% {
                        box-shadow: 0 0 50px rgb(251, 104, 178, 0.8);
                    }
                    10% {
                        box-shadow: 0 0 0 rgba(0,0,0,0);
                    }
                }
                
                &::after {
                    content: '';
                    height: 250%;
                    width: 80px;
                    background-color: rgba(255,255,255,0.5);
                    position: absolute;
                    left: -100px;
                    top: -75%;
                    transform: rotate(-25deg);
                    z-index: -1;
                    animation: sidebysideFlash 2s ease-in-out infinite;
                }
                
                @keyframes sidebysideFlash {
                    75% {
                        left: -100px;
                    }
                    100% {
                        left: 150%;
                    }
                }
        
                &:disabled {
                    opacity: 0.5;
                    background-color: grey;
        
                    &::after {
                        content: none;
                    }
                }
            }
        }


        .rightButton {
            .shareWRapper {
                position: relative;
                background-color: var(--color-baby-blue);
                height: 36px;
                gap: 8px;
                border-radius: 0 0 5px 0;
                
                .buttonOuterWrapper {
                    min-width: unset;
                    position: unset;
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                        font-size: 0.8rem;
                    }


                    .shareOptions {
                        // display: block;
                        top: unset;
                        bottom: 36px;
                        right: 0;
                        border-radius: 0;
                        box-shadow: none;

                        .option {
                            white-space: nowrap;
                            background-color: #000;
                            border-color: rgba(255,255,255,0.2);
                            padding: 8px 16px;
                            display: flex;
                            gap: 8px;
                            align-items: center;

                            &:hover {
                                background-color: #383838;
                            }
                        }
                    }
                }
            }
        }
    }


    &:hover {
        box-shadow: 2px 2px 0 #fff;
        
        .overlay {
            pointer-events: all;
            opacity: 1;
        }
    }

}
