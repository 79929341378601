.eventCard {
    width: 100%;
    height: 200px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 2px 2px 8px rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
    .eventCover {
        flex: 4;
        background-image: url('https://cdn.shopify.com/s/files/1/0425/2699/8690/files/Halloween_Candy_4_480x480.gif?v=1654058498');
        background-size: cover;
        background-position: bottom;
        height: 100%;
    }
    .eventDetails {
        flex: 8;
        height: 100%;
        padding: 15px 25px;
        position: relative;
        .actionsWraper {
            position: absolute;
            top: 15px;
            right: 0px;
            .editBtn {
                border: none;
                background-color: rgb(252, 197, 0);
                color: rgb(0, 0, 0);
                padding: 2px 25px;
                font-size: 0.8rem;
                font-weight: 600;
                // border-radius: 5px;
            }
            .deleteBtn {
                border: none;
                background-color: rgb(252, 0, 0);
                color: rgb(255, 255, 255);
                padding: 2px 25px;
                font-size: 0.8rem;
                font-weight: 600;
                // border-radius: 5px;
            }
        }
        .eventMetaWrapper {
            .labelWrapper {
                span {
                    font-weight: 600;
                }
            }
            .eventTitle {
                font-size: 1.4rem;
            }
            .eventTeamTitle {
                font-size: 1.1rem;
            }
            .inviteCode {
                font-size: 1rem;
            }
            .inviteLink {
                font-size: 1rem;
            }
        }
    }
    .shareWRapper {
        position: absolute;
        // bottom: 2rem;
        // left: 0px;
        // right: unset;
        .buttonOuterWrapper {
            button {
                font-size: 0.9rem;
            }
        }
    }
    .orgEventPopupButton {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
}