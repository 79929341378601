.shop-premium-candy-page-wrapper {
    .container {
        max-width: 1360px;
    }
    
    .site-header {
        position: relative;

        .inner-wrapper .right .nav-menu .nav-item:not(.cta-btn) {
            color: #000;
            
            &:hover {
                color: #fff;
            }
        }
        
        @media (max-width: 991px) {
            .inner-wrapper .right .nav-menu .nav-item:not(.cta-btn) {
                color: #fff;
            }
            .openMenu {
                i {
                    color: #000;
                }
            }
        }
    }
    
    
    .shop-premium-candy-page {
        margin: 100px 0;
        margin-top: 0;
    
        .store-top {
            box-shadow: 0 0 100px rgba(0,0,0,0.1);
            padding: 50px;
            border-radius: 30px;
            position: relative;
    
            .logo-image {
                width: 150px;
                height: 150px;
                overflow: hidden;
                border-radius: 100%;
                margin: -130px auto 20px;
                border: 3px solid var(--color-hot-pink);
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
    
    
            .subtitle {
                text-align: center;
                font-size: 20px;
                color: #858796;
                font-weight: bold;
            }
            .title {
                text-align: center;
                margin: 4px 0;
                font-size: 48px;
                font-weight: bold;
                color: #000;
            }
    
    
            .fundraise-section {
                margin-top: 32px;
    
                .inner {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    margin-bottom: 8px;
    
                    .left,
                    .right {
                        font-size: 20px;
                        font-weight: bold;
                        color: #858796;
    
                        span {
                            font-size: 30px;
                        }
                    }
                }
    
                .fund-track {
                    position: relative;
                    width: 100%;
                    box-shadow: 0 0 10px rgba(0,0,0,0.2);
                    border-radius: 100px;
                    padding: 8px;
    
                    .fund-track-complete {
                        display: block;
                        width: var(--complete-percent);
                        height: 10px;
                        background-color: var(--color-hot-pink);
                        border-radius: 100px;
                    }
                }
            }
        }
    
    
        .store-bottom {
            position: relative;
            // margin: 30px 0;
    
            .title {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;

                .left {
                    font-size: 32px;
                    color: #000;
                    font-weight: 600;
                    padding-bottom: 10px;
                }

                .right {
                    .search {
                        display: flex;
                        align-items: center;
                        border-bottom: 2px solid #000;
                        padding: 8px;

                        span {
                            margin-right: 16px;
                        }

                        .form-control {
                            border: 0;
                            border-radius: 0;
                            padding: 0;
                            height: auto;
                            box-shadow: none!important;
                        }
                    }
                }
            }

            .products {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 30px;
    
                .product {
                    background-color: #fff;
                    border: 1px solid #efefef;
                    border-radius: 30px;
                    transition: 0.2s;
                    padding: 20px;
                    padding-bottom: 80px;
                    position: relative;
    
                    .image-wrapper {
                        width: 100%;
                        height: 350px;
                        overflow: hidden;
                        border-radius: 30px;
    
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .name {
                        margin: 16px 0;
                        color: #000;
                        font-weight: 600;
                        font-size: 20px;
                    }

                    .price {
                        color: #000;
                        margin: 20px 0;
                        font-weight: 600;
                    }

                    .desc {
                        position: relative;
                        padding-bottom: 10px;
                        margin-bottom: 20px;

                        span {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            position: relative;
                        }

                        &.canHide {
                            &::after {
                                content: "Read more";
                                display: block;
                                text-align: right;
                                position: absolute;
                                bottom: -20px;
                                right: 0;
                                cursor: pointer;
                                font-size: 12px;
                                color: var(--color-hot-pink);
                                width: 100%;
                                text-align: right;
                            }
    
                            &.expanded {
                                span {
                                    display: block;
                                }
                                &::after {
                                    content: "Read less";
                                }
                            }
                        }
                    }

                    &:hover {
                        box-shadow: 0 0 50px rgba(0,0,0,0.1);
                    }
                }


                @media (max-width: 991px) {
                    grid-template-columns: 1fr 1fr;
                }
                @media (max-width: 767px) {
                    .product {
                        .image-wrapper {
                            height: 200px;
                        }
                    }
                }
                @media (max-width: 500px) {
                    grid-template-columns: 1fr;
                }
            }
        }
    }

    .actionWrapper {
        padding-top: 25px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        bottom: 20px;
        right: 20px;

        .addToCartBtn {
            border: none;
            background-color: var(--color-hot-pink);
            color: white;
            padding: 5px 25px;
        }
    }

}