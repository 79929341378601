
.videoWrapper {
    position: relative;
    .popupVideo {
        object-fit: cover;
    }
    .buttonsWrapper {
        position: absolute;
        // top: 20rem;
        // right: 30px;
        z-index: 99999999;
        .playButton {
            border: none;
            background-color: #FF69B4!important;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding:  3px 10px;
        }
        .muteButton {
            padding:  3px 10px;
            background-color: #FF69B4!important;
            border: none;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}