.event-view-page {
    .createStore {
        // margin-top: 18px;
        // height: 30px;
        border: none;
        background-color: var(--color-hot-pink);
        color: #000;
        font-weight: bold;
        text-transform: uppercase;
        padding: 8px 16px;
        border-radius: 5px;
        margin-bottom: 2rem;
        font-size: 18px;
    }
    .left {
        .innerContent {
            position: sticky;
            top: 10px;
            z-index: 9;

            .image-wrapper {
                width: 100%;
                height: 200px;
                overflow: hidden;
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
    
            .event-name {
                font-size: 24px;
                font-weight: 700;
                color: #000;
                margin: 20px 0;
                border-bottom: 1px solid #dfdfdf;
                padding-bottom: 4px;
                position: relative;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                @media (max-width: 767px) {
                    display: block;
                }
            }
    
            .desc {
                display: grid;
                grid-template-columns: 1fr 1fr;
    
                .item {
                    display: grid;
                    margin-bottom: 10px;
    
                    b {
                        color: #000;
                    }
                }

                @media (max-width: 767px) {
                    grid-template-columns: 1fr;
                }
            }
        }
    }


    .right {
        .popupStoreList {
            .title {
                font-size: 24px;
                color: #000;
                font-weight: 700;
                border-bottom: 1px solid #dfdfdf;
                padding-bottom: 4px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;

                .right {
                    background-color: rgb(0, 231, 50);
                    padding: 8px 24px;
                }

                @media (max-width: 767px) {
                    justify-content: center;
                    gap: 10px;
                    margin: 20px 0;
                    text-align: center;
                }
            }
    
            .popupStores {
                height: 80vh;
                overflow-y: scroll;
                overflow-x: hidden;
                padding: 10px;
                // background-color: #efefef;

                &::-webkit-scrollbar {
                    width: 3px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #000;
                }

                .popupStore {
                    border-radius: 10px;
                    overflow: hidden;
                    box-shadow: 0 0 20px rgba(0,0,0,0.2);
                    margin-bottom: 20px;
                    border: 2px solid rgba(0,0,0,0.2);
                    cursor: pointer;
                    transition: 0.2s;

                    &:hover {
                        border-color: var(--color-hot-pink);
                        box-shadow: none;
                    }

                    .coverImage {
                        width: 100%;
                        height: 80px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .details {
                        display: grid;
                        grid-template-columns: auto 1fr;
                        align-items: center;
                        grid-gap: 20px;
                        padding: 20px;
                        background-color: #fff;

                        .image-wrapper {
                            width: 80px;
                            height: 80px;
                            overflow: hidden;
                            border-radius: 100%;
                            border: 4px solid #fff;
                            box-shadow: 0 0 5px rgba(0,0,0,0.5);

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .right {
                            height: 100%;
                            display: grid;
                            align-items: space-between;

                            .store-name {
                                font-size: 20px;
                                font-weight: 700;
                                color: #000;
                            }

                            .goalWrapper {
                                display: grid;
                                grid-template-columns: auto 1fr auto;
                                grid-gap: 10px;
                                align-items: center;

                                .progressWrap {
                                    width: 100%;
                                    height: 12px;
                                    border: 1px solid #dfdfdf;
                                    position: relative;
                                    border-radius: 100px;

                                    .progressMade {
                                        // width: 70%;
                                        height: 5px;
                                        background-color: rgba(0,200,0);
                                        position: absolute;
                                        left: 2px;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        border-radius: 100px;
                                    }
                                }
                            }
                        }

                        @media (max-width: 767px) {
                            grid-template-columns: 1fr;
                        }
                    }
                }
            }
        }
    }


    .popupListItemDetail {
        position: relative;
        .ownStoreBadge {
            position: absolute;
            right: 0;
            top: 18px;
            font-size: 0.8rem;
            font-weight: 600;
            background-color: rgb(255, 174, 0);
            padding: 4px 25px;
            box-shadow: 4px 4px 10px rgba(0,0,0,0.2);
            color: black;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
    }
}