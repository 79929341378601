.popup-store-modal-inner {
    max-height: 100%;
    overflow-y: scroll;
    padding: 30px;

    &::-webkit-scrollbar {
        width: 0;
    }

    .modal-title {
        font-size: 20px;
        font-weight: 700;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 10px;
        margin-bottom: 20px;
        color: var(--color-hot-pink);
    }

    .form-group {
        .label {
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 4px;
        }

        &.iconMedia {
            .image-upload {
                width: 200px;
                margin: auto;
                border-radius: 100%;
                overflow: visible;

                img {
                    border-radius: 100%;
                }
            }
        }

        .input-group {
            span {
                background-color: #efefef;
                padding: 0 10px;
                display: flex;
                align-items: center;
                border-radius: 0.35rem 0 0 0.35rem;
                border: 1px solid #d1d3e2;
                border-right: 0;
            }
        }


    }

    .error-text {
        color: red !important;
    }
}

.loaderWrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.829);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999;
    display: flex;
    justify-content: center;
    align-items: center;

    &.pageLoader {
        height: 100vh;
        width: 100vw;
        z-index: 9999999999;
    }

    .loader {
        border: 4px solid #131212;
        /* Light grey */
        border-top: 4px solid #dd5100;
        /* Blue */
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 0.5s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    // pointer-events: none;

    .loadingProgress {
        position: relative;
        max-width: 500px;
        width: 100%;
        border: 2px solid var(--color-hot-pink);
        background: #fff;
        padding: 32px 24px;
        margin: 0 16px;
        box-shadow: 0 0 0 16px #fff;

        .label {
            position: absolute;
            background: #fff;
            top: -8px;
            left: 10px;
            font-weight: bold;
            color: var(--color-hot-pink);
            padding: 0 10px;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        .progressBar {
            background: #dfdfdf;

            .progress {
                background: var(--color-secondary-lightest);
                border-radius: 0;
            }
        }
    }
}

.required {
    color: red;
    font-size: 0.7rem;
    font-weight: 400;
}