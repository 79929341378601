.shareWRapper {
    position: absolute;
    // bottom: 6rem;
    top: 0;
    right: 0;

    .buttonOuterWrapper {
        button {
            font-size: 0.9rem;
        }
    }
}

.toggleWrapper {
    .options {
        display: flex;
        justify-content: center;
        background-color: rgb(228, 228, 228);
        border-radius: 15px;

        .option {
            padding: 5px 15px;

            &.selected {
                padding: 5px 15px;
                background-color: rgb(82, 206, 0);
                border-radius: 15px;
                box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
                color: white;
            }
        }
    }

}

.buttonsWrapper {
    position: relative;

    .tagsWrapper {
        // position: absolute;
        left: 0;
        top: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;

        *{ 
            font-size: 0.8rem;
            // font-weight: 600;
        }

        .eventTypeTag {
            padding: 3px 10px;
            background-color: #ffc31e;
            color: black;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;

            &.own {
                color: white;
                background-color: var(--color-hot-pink);
            }
        }

        .statusTag {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            padding: 3px 10px;
            background-color: #1ea5ff;
            color: black;

            &.Active {
                color: white;
                background-color: #00e732;
            }

            &.Expired {
                color: white;
                background-color: rgba(0,0,0,0.4);
            }
        }

    }
}




.shareInfoModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: 999999999;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: all;
    }

    .modalInner {
        background-color: #fff;
        max-width: 600px;
        height: auto;
        padding: 50px;
        position: relative;

        .closeModal {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 9999;
            padding: 20px;
            font-size: 24px;
            cursor: pointer;
        }

        .image-wrapper {
            width: 120px;
            height: 120px;
            overflow: hidden;
            margin: 0 auto;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .big {
            font-size: 32px;
            color: #000;
            font-family: var(--font-heading);
            text-align: center;
            margin: 24px 0;
        }

        .desc {
            p {
                color: #000;
            }
        }

        .checkbox {
            text-align: center;
            margin: 24px 0;
            font-size: 14px;
        }

        .buttons {
            text-align: center;
            display: flex;
            justify-content: center;
            gap: 24px;

            .btn {
                border-radius: 0;
                padding: 16px 32px;
                font-family: var(--font-heading);
                font-size: 18px;
            }
        }
    }



    @media (max-width: 767px) {
        .modalInner {
            // height: 100vh;
        }
    }
}



