.purchaseNoteInputWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    p {
        text-align: left;
        max-width: 500px;
        width: 100%;
        margin-bottom: 4px;
    }
    textarea {
        max-width: 500px;
        width: 100%;
    }
}