.manage-popup-store-page {
    .org-card {
        padding: 0;
        cursor: pointer;
        
        .big-image {
            width: 100%;
            height: 200px;
            overflow: hidden;
            border-radius: 5px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .details {
            padding: 20px
        }
    }




    // preview code
    .top {
        .box-wrapper {
            text-align: center;
            box-shadow: 0 0 100px rgba(0,0,0,0.1);
            background: #fff;
            border: 1px solid #dfdfdf;
            border-radius: 30px;
            padding: 30px;

            .image-square {
                width: 150px;
                height: 150px;
                margin: auto;
                overflow: hidden;
                border-radius: 100%;
                border: 2px solid var(--color-hot-pink);
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
    
            .subtitle {
                margin: 16px 0;
                font-size: 20px;
                font-weight: 600;
            }

            .title {
                margin: 16px 0;
                font-size: 32px;
                font-weight: bold;
                color: #000;
            }

            .goal {
                text-align: center;

                div {
                    display: inline-block;
                    background-color: #efefef;
                    padding: 12px 24px;
                    color: var(--color-hot-pink);
                    font-weight: 600;

                    span {
                        font-size: 150%;
                        font-weight: bold;
                    }
                }
            }
        }

        .image-big {
            width: 100%;
            height: 200px;
            overflow: hidden;
            border-radius: 10px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .description {
            color: #000;
            margin-top: 16px;
        }


        

        @media (max-width: 767px) {
            .box-wrapper {
                margin-bottom: 20px;

                .title {
                    font-size: 24px;
                }
            }
        }
    }




    .leaderboard,
    .supporters {
        margin: 48px 0;

        .title {
            font-size: 20px;
            color: #858796;
            font-weight: 600;
            margin-bottom: 15px;
            border-bottom: 1px solid #dfdfdf;
            padding-bottom: 10px;
        }

        .items {
            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                .left {
                    display: flex;
                    align-items: center;

                    .image-wrapper {
                        margin-right: 8px;
                        width: 50px;
                        height: 50px;
                        overflow: hidden;
                        border-radius: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .seeall {
            text-align: center;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;
            color: var(--color-hot-pink);
            display: block;
            cursor: pointer;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }



    .products {
        .product {
            padding: 20px;
            border-radius: 20px;
            border: 1px solid #dfdfdf;

            .image-wrapper {
                width: 100%;
                height: 300px;
                overflow: hidden;
                border-radius: 20px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .name {
                text-align: center;
                font-size: 20px;
                color: #000;
                font-weight: 600;
            }

            .price {
                text-align: center;
                margin: 8px 0;
                color: #000;
                font-weight: bold;
            }

            .desc {
                text-align: center;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                overflow: hidden;
            }
        }
    }
}



.modal-dialog {
    position: fixed;
    right: 0;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal.right .modal-body {
    padding: 15px 15px 80px;
}

.modal.right.fade .modal-dialog {
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
    right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
    border-radius: 0;
    border: none;
}

.modal-header {
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
}

/* ----- v CAN BE DELETED v ----- */

.org-card {
    background-color: rgb(255, 255, 255);
    padding: 25px 15px 25px 15px;
    margin-bottom: 10px;
    border: 1px solid rgb(208, 208, 208);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.154);
    border-radius: 5px;
    height: 180px;
    position: relative;

    .orgIcon {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 2rem;
    }

    &.create {
        display: flex;
        cursor: pointer;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 0;
        color: var(--color-hot-pink);

        .icon {
            font-size: 1.9rem;
            margin-bottom: 0px;
        }
    }

    .orgCode {
        margin-top: rem;
        font-size: 0.7rem;
        font-weight: 600;
    }

    .orgType {
        margin-top: -0.3rem;
        // margin-bottom: -0.3rem;
        font-size: 0.7rem;
        font-weight: 600;
    }

}
.eventManageBtn {
    margin-top: -8px;
    margin-bottom: 8px;
    font-size: 0.8rem;
    border-radius: 4px;
    padding: 1px 20px;
    background-color: var(--color-hot-pink);
    border: 2px solid #000;
    
    color: white;
    cursor: pointer;
    &:hover {
        color: white!important;
    }
    &:disabled {
        opacity: 0.5;
        background-color: grey;
    }
}