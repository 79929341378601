.popup-store-new-page-wrapper {
    .site-header {
        .inner-wrapper {
            .left .logo {
                transition: 0.2s;
                img {
                    transition: 0.2s;
                }
            }

            .right {
                .nav-menu {
                    .nav-item:not(.cta-btn) {
                        color: var(--color-secondary-darkest);
                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }


    .storeInnerWrapper {
        display: grid;
        grid-template-columns: 1fr 2fr;
        

        .storeLeft {
            height: 100vh;
            overflow: hidden;
            display: grid;
            grid-template-rows: 50% 50%;

            .storeLeft_top {
                background-color: var(--color-secondary-darkest);
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 70px;

                .storeDetails {
                    width: 80%;

                    .item {
                        display: grid;
                        grid-template-columns: 30px 1fr;
                        gap: 16px;
                        align-items: center;
                        margin: 20px 0;
                        color: #fff;
                        font-family: var(--font-heading);

                        i {
                            font-size: 24px;
                        }
                        span {
                            color: rgba(255,255,255,0.5);
                        }


                        &.fund-raised {
                            align-items: flex-start;
                            margin-top: 30px;
                            i {
                                padding-top: 4px;
                            }

                            .right {
                                span {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    color: #fff;
                                    font-size: 20px;
                                }
    
                                .fund-track {
                                    margin-top: 20px;
                                    position: relative;
                                    width: 100%;
                                    // box-shadow: 0 0 10px rgba(0,0,0,1);
                                    border-radius: 100px;
                                    padding: 4px;
                                    border: 2px solid rgba(255,255,255,0.2);
                
                                    .fund-track-complete {
                                        display: block;
                                        width: var(--complete-percent);
                                        height: 6px;
                                        background-color: var(--color-hot-pink);
                                        border-radius: 100px;
                                    }
                                }
                            }
                        }

                    }


                    .buttons {
                        text-align: center;

                        .donateButton {
                            display: inline-block;
                            cursor: pointer;
                            padding: 8px 16px;
                            background-color: var(--color-hot-pink);
                            color: #fff;
                            font-size: 16px;
                            transition: 0.2s;

                            span {
                                margin-left: 8px;
                                font-family: var(--font-heading);
                            }

                            &:hover {
                                filter: brightness(1.5);
                            }
                        }
                    }
                }
            }

            .storeLeft_bottom {
                .innerWrapper {
                    display: flex;
                    height: 100%;
                    border-right: 2px solid var(--color-secondary-darkest);
    
                    .leaderboardWrapper,
                    .recentSupportersWrapper {
                        flex: 0 0 0%;
                        width: 0%;
                        transition: 0.2s;
                        position: relative;
                        background-color: #fff;

                        .sectionTitle {
                            background-color: var(--color-secondary-lightest);
                            color: #fff;
                            font-family: var(--font-heading);
                            font-size: 30px;
                            text-transform: uppercase;
                            letter-spacing: 2px;
                            cursor: pointer;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            z-index: -1;
                            writing-mode: vertical-rl;
                            text-orientation: mixed;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 8px;
                            transition: 0.2s;

                            &:hover {
                                filter: brightness(1.5);
                            }
                        }

                        .sectionContent {
                            position: relative;
                            overflow-y: hidden;
                            height: 100%;
                            margin: 8px;
                            padding: 0 10px;

                            &:hover {
                                overflow-y: scroll;
                            }

                            &::-webkit-scrollbar {
                                width: 4px;
                            }
                            &::-webkit-scrollbar-thumb {
                                background-color: #dfdfdf;
                                border-radius: 100px;
                                
                                &:hover {
                                    width: 10px;
                                    padding: 10px;
                                    background: var(--color-secondary-darkest);
                                }
                            }

                            .title {
                                font-size: 20px;
                                color: #000;
                                font-family: var(--font-heading);
                                margin-bottom: 20px;
                                padding-bottom: 10px;
                                text-align: center;
                                position: relative;

                                &::after {
                                    content: '';
                                    height: 2px;
                                    width: 50px;
                                    background-color: var(--color-hot-pink);
                                    position: absolute;
                                    bottom: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                            }
                        }
                        
                        &.expand {
                            flex: 0 0 100%;
                            width: 100%;

                            .sectionTitle {
                                z-index: 999;
                            }
                            .sectionContent {
                                z-index: 99;
                            }
                        }
                    }

                    .leaderboardWrapper {
                        .sectionTitle {
                            right: 0;
                        }
                        
                        .sectionContent {
                            padding-right: 64px;


                            .inner {
                                .first-three {
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: center;
                                    grid-gap: 24px;

                                    .person {
                                        flex: 0 0 20%;
                                        width: 100%;
                                        text-align: center;
    
                                        .image-wrapper {
                                            width: 120px;
                                            height: 120px;
                                            // overflow: hidden;
                                            position: relative;
    
                                            img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                            }

                                            .icon {
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 30px;
                                                height: 30px;
                                                
                                                svg {
                                                    width: 100%;
                                                    height: 100%;
                                                }
                                            }
                                        }
    
                                        .name {
                                            font-weight: 600;
                                            color: #000;
                                        }

                                        .amount {
                                            color: var(--color-hot-pink);
                                            font-weight: 700;
                                        }
                                    }
                                }

                                .others {
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: center;
                                    grid-gap: 24px;
                                    margin: 20px 0;

                                    .person {
                                        text-align: center;
    
                                        .image-wrapper {
                                            width: 80px;
                                            height: 80px;
                                            overflow: hidden;
    
                                            img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                            }
                                        }
    
                                        .name {
                                            font-weight: 600;
                                            color: #000;
                                            font-size: 12px;
                                        }
                                        
                                        .amount {
                                            font-size: 12px;
                                            color: var(--color-hot-pink);
                                            font-weight: 700;
                                        }
                                    }
                                }
                            }
                        }
                    }



                    .recentSupportersWrapper {
                        .sectionTitle {
                            left: 0;
                        }

                        .sectionContent {
                            padding-left: 64px;

                            .supporter {
                                display: grid;
                                grid-template-columns: 50px 1fr auto;
                                gap: 16px;
                                align-items: center;
                                margin-bottom: 20px;

                                .image-wrapper {
                                    width: 50px;
                                    height: 50px;
                                    overflow: hidden;
                                    border-radius: 100%;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }

                                .name {
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #000;

                                    .timestamp {
                                        color: rgba(0,0,0,0.2);
                                    }
                                }
                                
                                .amount {
                                    font-size: 16px;
                                    color: #666666;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }

        .storeRight {
            height: 100vh;
            overflow-y: scroll;
            overflow-x: hidden;
            display: grid;
            grid-template-rows: 50% 50%;
            transition: 0.2s;

            .storeRight_top {
                position: sticky;
                top: 0;
                background-color: #ffffff;
                z-index: 9;
                display: flex;
                align-items: flex-end;
                padding: 24px;
                box-shadow: 0 0 20px rgba(0,0,0,0.2);


                .storeName {
                    font-family: var(--font-heading);
                    font-size: 56px;
                    color: #000;
                }
            }
            
            .storeRight_bottom {
                .coverImage {
                    width: 100%;
                    max-height: 1000px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }


                .fundDetails {
                    padding: 30px;

                    .smallDetails {
                        display: grid;
                        grid-template-columns: repeat(4,1fr);
                        justify-content: space-between;
                        align-items: center;
                        margin: 16px 0 32px;
                        border: 1px solid rgba(0,0,0,0.2);
                        border-right: 0;
                        border-left: 0;

                        .item {
                            color: #000;
                            text-align: center;
                            padding: 8px 0;
                            font-size: 12px;
                            font-style: italic;

                            i {
                                color: #666;
                                margin-right: 8px;
                            }
                            
                            &:not(:last-child) {
                                border-right: 1px solid rgba(0,0,0,0.2);
                            }
                        }
                    }

                    .fundDesc {
                        .descInnerWrapper {
                            font-style: italic;
                            color: rgba(0,0,0,0.8);
                            border-left: 4px solid rgba(0,0,0,0.2);
                            padding: 10px;
                            padding-left: 20px;
                            position: relative;
                            height: 200px;
                            overflow: hidden;

                            &::after {
                                content: '';
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                background-image: linear-gradient(transparent, #fff);
                            }

                            &.expand {
                                height: auto;
                                &::after {
                                    content: none;
                                }
                            }
                        }

                        .readMore {
                            text-align: center;
                            margin: 16px 0;
                            cursor: pointer;
                            color: var(--color-hot-pink);

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
                

                .candyStore {
                    padding: 50px;

                    .title {
                        font-size: 32px;
                        color: #000;
                        font-family: var(--font-heading);
                        margin-bottom: 20px;
                        padding-bottom: 10px;
                        text-align: center;
                        position: relative;

                        span {
                            font-size: 40px;
                        }
                    }


                    .candies {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        gap: 16px;
                        
                        .candy {
                            border: 1px solid transparent;
                            padding: 10px;
                            transition: 0.2s;

                            .image-wrapper {
                                width: 100%;
                                height: 250px;
                                overflow: hidden;
                                position: relative;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                                .buttons {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    bottom: -100px;
                                    // background-color: #fff;
                                    text-align: center;
                                    padding: 8px;
                                    transition: 0.2s;

                                    .addToCartBtn {
                                        display: inline-block;
                                        background-color: var(--color-hot-pink);
                                        font-family: var(--font-heading);
                                        color: #fff;
                                        padding: 8px 16px;
                                        cursor: pointer;

                                        &:hover {
                                            filter: brightness(1.5)
                                        }
                                    }
                                }
                            }

                            .candyName {
                                font-size: 24px;
                                color: #000;
                                font-weight: 600;
                                margin: 8px 0;
                            }
                            
                            .price {
                                font-size: 20px;
                                font-weight: 600;
                                color: var(--color-hot-pink);
                                margin: 8px 0;
                            }

                            .desc {
                                font-size: 16px;
                                color: #000;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                                overflow: hidden;
                            }

                            &:hover {
                                border-color: #666666;

                                .image-wrapper {
                                    .buttons {
                                        bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }





    
    &.sticky {
        .site-header {
            padding: 3px;

            .inner-wrapper .left .logo {
                width: 80px;
                height: 80px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .storeInnerWrapper {
            .storeRight {
                grid-template-rows: 25% 75%;

                .storeRight_top {
                    .storeName {
                        font-size: 32px;
                    }
                }
            }
        }
    }



    @media (max-width: 767px) {
        .storeInnerWrapper {
            display: block;

            .storeLeft,
            .storeRight {
                display: block;
                height: auto;
            }
        }
    }
}