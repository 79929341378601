
.orgInputWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 15px;
    .orginput {
        label {
            font-size: 0.8rem;
            font-weight: 600;
            color: grey;
        }
        input {
            font-size: 0.8rem;
            width: 100%;
            padding-left: 5px;
            border: none;
            border-radius: 4px;
            background-color: rgb(213, 213, 213);
        }
        .inputError {
            font-size: 0.7rem;
            height: 11px;
        }
    }
    .orgCreateBtn {
        margin-top: 8px;
        font-size: 0.8rem;
        padding: 1px 20px;
        background-color: rgb(0, 141, 33);
        border: none;
        color: white;
        cursor: pointer;
        &:disabled {
            opacity: 0.5;
            background-color: grey;
        }
    }

    .orgTypeSelector {
        font-size: 0.8rem;
            width: 100%;
            border: none;
            border-radius: 4px;
            background-color: rgb(213, 213, 213);
    }

}