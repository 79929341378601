#payment-form {
    button {
        background-color: rgb(6, 184, 0);
        color: white;
        border: none;
        border-radius: 15px;
        padding: 5px 45px;
        margin-top: 35px;
    }
    
}